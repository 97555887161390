/* eslint-disable */
import Resource from 'common/util/Resource';

(function(window, document, tag, url, name, wid, a, m) {
    window[name] = window[name] || function() {
        (window[name].q = window[name].q || []).push(arguments);
        window[name].handler && window[name].handler(arguments);
    },
    window[name].l = 1 * new Date(),
    window[name].wid = wid,
    a = document.createElement(tag),
    m = document.getElementsByTagName(tag)[0],
    a.async = 1,
    a.setAttribute("charset", "utf-8"),
    a.src = url,
    m.parentNode.insertBefore(a, m)
})(window, document, "script", Resource.getSitePreference('chatConfigs', 'chatIncludeJsUrl'), "_dixa", Resource.getSitePreference('chatConfigs', 'chatClientId'));
