export default class Resource {
    static icon(symbol, size = 12) {
        return `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">
            <use xlink:href="${window.Icons[symbol]}"></use>
        </svg>`;
    }

    static msg(key, file) {
        let label = key;

        try {
            label = window.Resources[file][key];
        } catch (e) {
            console.warn(`Could not retrieve property label for ${key}`);
        }

        return label;
    }

    static url(key) {
        let label = key;

        try {
            label = window.Urls[key];
        } catch (e) {
            console.warn(`Could not retrieve URL for ${key}`);
        }

        return label;
    }

    static getSitePreference(folder, key) {
        let preferences = key;

        try {
            preferences = window.SitePreferences[folder][key];
        } catch (e) {
            console.warn(`Could not retrieve preferences for ${key}`);
        }

        return preferences;
    }
}
